import { cn } from '@/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './tooltip';
export function MultiToggle<T>({
  value,
  onChange,
  options,
  className
}: {
  value: T;
  options: {
    label: React.ReactNode;
    value: T;
    className?: (selected: boolean) => string;
    tooltip?: React.ReactNode;
  }[];
  onChange: (value: T) => void;
  className?: string;
}) {
  return <div className={cn('flex gap-2 rounded-md bg-muted p-1', className)} data-sentry-component="MultiToggle" data-sentry-source-file="multi-toggle.tsx">
      {options.map((option, i) => <TooltipProvider key={i} delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <button key={i} className={cn('flex items-center rounded-sm p-0.5 transition-all duration-300', value === option.value && 'bg-primary text-primary-foreground', option.className?.(value === option.value))} onClick={() => onChange(option.value)}>
                {option.label}
              </button>
            </TooltipTrigger>
            {option.tooltip && <TooltipContent className="max-w-48">
                {option.tooltip}
              </TooltipContent>}
          </Tooltip>
        </TooltipProvider>)}
    </div>;
}