'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/nextjs';
import { Info, Rocket, Send } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Form, FormErrorRootMessage } from '../forms/Form';
import { FormTextField } from '../forms/FormTextField';
import SubmitButton from '../forms/SubmitButton';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Button } from '../ui/button';
import { loginAction } from './actions';
const formSchema = z.object({
  email: z.string().email('Enter valid email')
});
type SignupFormValues = z.infer<typeof formSchema>;
export default function LoginForm() {
  const posthog = usePostHog();
  const form = useForm<SignupFormValues>({
    defaultValues: {
      email: ''
    },
    resolver: zodResolver(formSchema)
  });
  const [submitState, setSubmitState] = useState<'sent' | 'added'>('sent');
  useEffect(() => {
    const error = new URLSearchParams(window.location.search).get('error_description');
    if (error) {
      form.setError('root', {
        message: error,
        type: 'invalid'
      });
    }
  });
  return <Form form={form} onSubmit={async values => {
    form.clearErrors('root');
    const result = await loginAction({
      email: values.email,
      origin: window.location.origin,
      next: new URL(window.location.href).searchParams.get('next') ?? undefined
    });
    if (result === 'error' || result === 'error_adding' || result === 'error_sending') {
      form.setError('root', {
        message: result === 'error_adding' ? "We couldn't add you to the waitlist. Please try again later." : result === 'error_sending' ? "We couldn't send you a login link. Please try again later." : 'Something went wrong. Please try again later.',
        type: 'invalid'
      });
      captureException(`SignUp/SignIn Error: ${result}`);
      posthog.capture('auth_form_failed', {
        email: values.email,
        message: result
      });
      return;
    }
    setSubmitState(result);
    posthog.capture('auth_form_submitted', {
      email: values.email,
      type: result === 'added' ? 'waitlist' : 'login'
    });
  }} className="mx-auto flex w-full max-w-sm flex-col place-content-center items-stretch gap-4 px-4 *:animate-in *:fade-in *:slide-in-from-bottom-4" data-sentry-element="Form" data-sentry-component="LoginForm" data-sentry-source-file="login-form.tsx">
      {form.formState.isSubmitSuccessful ? submitState === 'sent' ? <>
            <p className="text-xl font-bold">
              Check your inbox!
              <Send size={20} className="ml-2 inline duration-500 animate-in fade-in slide-in-from-bottom-4 slide-in-from-left-4" />
            </p>
            <p className="text-sm text-muted-foreground">
              Click the link in the email to continue.
            </p>
            <Alert variant={'default'} className="mt-8">
              <Info size={16} />
              <AlertTitle className="font-bold">
                Haven&apos;t received an email?
              </AlertTitle>
              <AlertDescription>
                Check your spam folder or{' '}
                <button type="button" onClick={() => form.reset()} className="underline">
                  try sending another one
                </button>
              </AlertDescription>
            </Alert>
          </> : <>
            <p className="flex items-start text-xl font-bold">
              <Rocket className="m-3 inline size-9 shrink-0 duration-500 animate-in fade-in slide-in-from-bottom-4 slide-in-from-left-4" />
              You&apos;ve secured a spot on our waitlist!
            </p>
            <p className="text-sm text-muted-foreground">
              We&apos;re currently in private beta, but we&apos;re working hard
              to expand our user base. Thank you for your interest! We&apos;ll
              reach out as soon as we can welcome you aboard.
            </p>
            <Button type="button" onClick={() => form.reset()} variant={'ghost'}>
              Add another email
            </Button>
          </> : <>
          <h2 className="text-xl font-bold">Sign in to continue</h2>
          <FormTextField form={form} name="email" label="Email" placeholder="Enter your email" autoComplete="email" />
          <FormErrorRootMessage />
          <SubmitButton submitting={form.formState.isSubmitting} label="Continue" />
          <Alert variant={'default'} className="mt-8">
            <Info size={16} className="" />
            <AlertTitle className="font-bold">
              Why do you need to sign in?
            </AlertTitle>
            <AlertDescription>
              To help us prevent abuse and give you the full functionality, you
              need to have an acccount to use our services.
            </AlertDescription>
          </Alert>
        </>}
    </Form>;
}