import { useChat } from '@/lib/hooks/use-chat';
import { RefreshCcw, TriangleAlert } from 'lucide-react';
import { Button } from '../ui/button';
export function ChatError() {
  const {
    error,
    reload
  } = useChat();
  if (!error) return null;
  return <div className="my-4 flex h-fit items-start gap-2 rounded-xl border border-destructive p-2 text-destructive sm:items-center" data-sentry-component="ChatError" data-sentry-source-file="error.tsx">
      <TriangleAlert size={20} className="m-0.5 shrink-0" data-sentry-element="TriangleAlert" data-sentry-source-file="error.tsx" />
      Oops, something went wrong...
      <Button variant={'destructive'} className="ms-auto gap-2" type="button" onClick={() => reload()} data-sentry-element="Button" data-sentry-source-file="error.tsx">
        <RefreshCcw size={20} className="m-0.5 shrink-0" data-sentry-element="RefreshCcw" data-sentry-source-file="error.tsx" />
        Reload
      </Button>
    </div>;
}