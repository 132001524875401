import { Markdown } from '@/components/chat/markdown';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { IconOpenAI } from '@/components/ui/icons';
import { MultiToggle } from '@/components/ui/multi-toggle';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useChat } from '@/lib/hooks/use-chat';
import { useCopyToClipboard } from '@/lib/hooks/use-copy-to-clipboard';
import { SupabaseMessage } from '@/lib/supabase/actions';
import { User } from '@/lib/types';
import { cn, formatCents, formatDollars } from '@/lib/utils';
import dedent from 'dedent';
import { motion } from 'framer-motion';
import { Check, CircleCheck, Copy, Pencil, Send, Trash, X } from 'lucide-react';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Textarea } from '../ui/textarea';
import { ChatModelPicker } from './chat-model-picker';
import { ModelPicker } from './model-picker';
const MessageContext = createContext<{
  content: string;
  message: SupabaseMessage;
  alignment: 'left' | 'right';
  isInContext: boolean;
  isContextAuto: boolean;
  defaultContextLength: number;
  toggleInContext: (isInContext: boolean | null) => void;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
} | null>(null);
function useMessage() {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageContext.Provider');
  }
  return context;
}
function ContextManagement() {
  const [openContextManagement, setOpenContextManagement] = useState(false);
  const isHovering = useRef<boolean>(false);
  const {
    alignment,
    message,
    isInContext,
    isContextAuto,
    defaultContextLength,
    toggleInContext
  } = useMessage();
  return <div className={cn('flex border-spacing-1 flex-row items-stretch gap-2 rounded-md outline outline-offset-4 outline-transparent transition-all duration-500', openContextManagement && 'mx-2 outline-muted', alignment === 'left' && 'flex-row-reverse')} onMouseEnter={() => {
    isHovering.current = true;
  }} onMouseLeave={() => {
    isHovering.current = false;
    setTimeout(() => isHovering.current || setOpenContextManagement(false), 1000);
  }} data-sentry-component="ContextManagement" data-sentry-source-file="messages.tsx">
      <TooltipProvider delayDuration={100} data-sentry-element="TooltipProvider" data-sentry-source-file="messages.tsx">
        <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="messages.tsx">
          <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="messages.tsx">
            <Button variant={'ghost'} size={'icon'} id={`context-toggle-${message.id}`} className={cn('flex justify-end overflow-auto p-1 px-2 text-sm font-medium leading-4', alignment === 'right' ? 'flex-row' : 'flex-row-reverse')} onClick={() => {
            setOpenContextManagement(true);
            setTimeout(() => {
              toggleInContext(isContextAuto ? !isInContext : null);
            }, 200);
          }} data-sentry-element="Button" data-sentry-source-file="messages.tsx">
              Context
              {isInContext ? <Check size={16} className={cn('shrink-0 text-green-500 transition-transform duration-500')} /> : <X size={16} className={cn('shrink-0 text-destructive transition-transform duration-500')} />}
            </Button>
          </TooltipTrigger>
          <TooltipContent className="max-w-48" data-sentry-element="TooltipContent" data-sentry-source-file="messages.tsx">
            Control whether the AI should see this message
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <MultiToggle className={cn('max-w-32 overflow-hidden transition-all duration-500', !openContextManagement && 'max-w-0 px-0')} value={isContextAuto ? null : isInContext} options={[{
      label: <Check size={16} />,
      value: true,
      className: s => cn('text-green-500', s && 'bg-green-500 text-destructive-foreground'),
      tooltip: 'Force include this message in AI context when generating next responses.'
    }, {
      label: <span className="text-xs font-semibold">Auto</span>,
      className: s => cn('px-1', s && 'bg-muted-foreground'),
      value: null,
      tooltip: <>
                The AI will have access to this message only if it fits into the
                default context length. Your default context length is{' '}
                {defaultContextLength}. TODO: Link to the chat settings.
              </>
    }, {
      label: <X size={16} />,
      value: false,
      className: s => cn('text-destructive', s && 'bg-destructive text-destructive-foreground'),
      tooltip: 'Remove this message from the AI context.'
    }]} onChange={v => {
      toggleInContext(v);
      setOpenContextManagement(false);
    }} data-sentry-element="MultiToggle" data-sentry-source-file="messages.tsx" />
    </div>;
}
function PriceTag({
  price = 0,
  tooltip
}: {
  price?: number;
  tooltip: React.ReactNode;
}) {
  return <TooltipProvider delayDuration={0} data-sentry-element="TooltipProvider" data-sentry-component="PriceTag" data-sentry-source-file="messages.tsx">
      <Tooltip data-sentry-element="Tooltip" data-sentry-source-file="messages.tsx">
        <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="messages.tsx">
          {price > 0 ? <div className="ms-2 rounded-full border border-white bg-muted px-1.5 py-0.5 font-mono text-xs font-medium text-foreground">
              {formatCents(price)}
            </div> : <div className="ms-2 rounded-lg bg-green-700 px-1.5 py-0.5 text-xs font-semibold text-white">
              Free
            </div>}
        </TooltipTrigger>
        <TooltipContent side="bottom" align="end" sideOffset={6} collisionPadding={12} className="max-w-64" data-sentry-element="TooltipContent" data-sentry-source-file="messages.tsx">
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>;
}
function Price() {
  const {
    message: {
      llm_usage
    }
  } = useMessage();
  if (!llm_usage) return null;
  if (!llm_usage.pricing) return <PriceTag tooltip={'This model is free'} />;
  if (llm_usage.pricing.per_input_token === 0 && llm_usage.pricing.per_output_token === 0 && llm_usage.pricing.per_message === 0) {
    if (llm_usage.pricing.per_month === 0) {
      return <PriceTag tooltip={'This model is free'} />;
    } else {
      return <PriceTag tooltip={<>
              This model is free per message, but has a monthly fee of{' '}
              {formatDollars(llm_usage.pricing.per_month)}
            </>} />;
    }
  }
  const cost = llm_usage.pricing ? llm_usage.input_tokens * llm_usage.pricing!.per_input_token + llm_usage.output_tokens * llm_usage.pricing!.per_output_token + llm_usage.pricing!.per_message : 0;
  if (cost === 0) return <PriceTag tooltip={'Your messsage was so small it is free'} />;
  return <PriceTag price={cost} tooltip={<div className="font-mono *:flex *:justify-between *:gap-4 *:text-xs">
          <div className="f">
            <span className="font-semibold">Input:</span>
            <span>
              {dedent`
            ${llm_usage.input_tokens} *
            ${formatCents(llm_usage.pricing!.per_input_token * 1000, {
          decimals: 3,
          fixed: true
        })}/k = 
            ${formatCents(llm_usage.input_tokens * llm_usage.pricing!.per_input_token, {
          decimals: 3,
          fixed: true
        })}
            `}
            </span>
          </div>
          <div className="text-xs">
            <span className="font-semibold">Output: </span>
            {dedent`
            ${llm_usage.output_tokens} *
            ${formatCents(llm_usage.pricing!.per_output_token * 1000, {
        decimals: 3,
        fixed: true
      })}/k = 
            ${formatCents(llm_usage.output_tokens * llm_usage.pricing!.per_output_token, {
        decimals: 3,
        fixed: true
      })}
            `}
          </div>
          {llm_usage.pricing!.per_message > 0 && <div className="text-xs">
              <span className="font-semibold">Per message: </span>
              {formatCents(llm_usage.pricing!.per_message, {
        decimals: 5,
        fixed: true
      })}
            </div>}
          {llm_usage.pricing!.per_month > 0 && <div className="text-xs">
              <span className="font-semibold">Monthly subscription: </span>
              {formatDollars(llm_usage.pricing!.per_month)}
            </div>}
        </div>} data-sentry-element="PriceTag" data-sentry-component="Price" data-sentry-source-file="messages.tsx" />;
}
function getMessageColors(message: SupabaseMessage, currentUser: User): {
  body: string;
  header: string;
} {
  if (message.role === 'user' && message.owner_id === currentUser.id) {
    return {
      body: 'bg-emerald-200 dark:bg-emerald-700 dark:text-white',
      header: 'bg-emerald-300 dark:bg-emerald-600'
    };
  }
  if (message.role === 'user') {
    return {
      body: 'border border-muted bg-muted',
      header: 'bg-muted'
    };
  }
  return {
    body: 'bg-zinc-100 dark:bg-zinc-900',
    header: 'bg-zinc-100 dark:bg-zinc-800 '
  };
}
function LLMName() {
  const {
    availableModels,
    regenerate,
    isLoading
  } = useChat();
  const {
    message: {
      id,
      llm_usage
    },
    alignment
  } = useMessage();
  return <ModelPicker model={llm_usage?.llm ?? undefined} setModel={model => {
    regenerate(id, {
      model: availableModels.find(m => m.id === model?.id)
    });
  }} availableModels={availableModels} className={cn('border-none bg-transparent text-base font-semibold shadow-none hover:bg-white/10', alignment === 'left' && 'me-auto')} title="Choose a model to regenerate the response" data-sentry-element="ModelPicker" data-sentry-component="LLMName" data-sentry-source-file="messages.tsx" />;
}
function Header({
  currentUser,
  hideActions
}: {
  currentUser: User;
  hideActions?: boolean;
}) {
  const {
    removeMessage
  } = useChat();
  const {
    message,
    content,
    alignment,
    setIsEditing,
    isEditing
  } = useMessage();
  const {
    isCopied,
    copyToClipboard
  } = useCopyToClipboard({
    timeout: 2000
  });
  return <div className={cn('sticky top-0 z-10 w-full bg-background')} data-sentry-component="Header" data-sentry-source-file="messages.tsx">
      <div className={cn('flex w-full flex-wrap items-center gap-1 rounded-t-2xl bg-inherit p-2 shadow-lg sm:px-4', alignment === 'right' ? 'flex-row-reverse' : 'flex-row', getMessageColors(message, currentUser).header)}>
        {message.role === 'assistant' ? <LLMName /> : <p className={cn('text-sm font-medium', alignment === 'right' ? 'ms-auto' : 'me-auto')}>
            {message.role === 'user' ? message.user?.username : 'Unknown sender'}
          </p>}
        {!hideActions && <>
            <ContextManagement />
            {alignment === 'right' && <Button variant={'ghost'} size={'icon'} onClick={() => setIsEditing(p => !p)}>
                <Pencil size={16} />
                <span className="sr-only">Edit message</span>
              </Button>}
            <Button variant={isCopied ? 'success' : 'ghost'} size={'icon'} onClick={() => copyToClipboard(content)}>
              {isCopied ? <CircleCheck size={16} className="animate-in fade-in slide-in-from-bottom-2" /> : <Copy size={16} className="animate-in fade-in slide-in-from-bottom-2" />}
              <span className="sr-only">Copy message</span>
            </Button>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant={'destructive-ghost'} size={'icon'}>
                  <Trash size={16} />
                  <span className="sr-only">Delete message</span>
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This action cannot be undone. This will permanently delete
                    this message and remove it from our servers.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction variant={'destructive'} onClick={() => removeMessage(message.id)}>
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <Price />
          </>}
      </div>
    </div>;
}
export function Message({
  className,
  currentUser,
  message,
  hideActions,
  isInContext,
  isContextAuto,
  toggleInContext,
  defaultContextLength
}: {
  className?: string;
  currentUser: User;
  message: SupabaseMessage;
  hideActions?: boolean;
  isInContext: boolean;
  isContextAuto: boolean;
  toggleInContext: (isInContext: boolean | null) => void;
  defaultContextLength: number;
}) {
  const alignment = message.role === 'user' && message.user?.id === currentUser.id ? 'right' : 'left';
  const [isEditing, setIsEditing] = useState(false);
  const [newValue, setNewValue] = useState(message.content?.toString() ?? '');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);
  const {
    regenerate
  } = useChat();
  return <MessageContext.Provider value={{
    content: message.content?.toString() ?? '',
    alignment,
    message,
    isInContext,
    isContextAuto,
    defaultContextLength,
    toggleInContext,
    isEditing,
    setIsEditing
  }} data-sentry-element="unknown" data-sentry-component="Message" data-sentry-source-file="messages.tsx">
      <div
    // https://github.com/francoismassart/eslint-plugin-tailwindcss/issues/294
    // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
    className={cn('relative flex flex-col overflow-clip rounded-2xl', alignment === 'right' ? 'ms-3 items-end sm:ms-8' : 'me-3 items-start sm:me-8', !isInContext && 'opacity-50', getMessageColors(message, currentUser).body, className)}>
        <Header currentUser={currentUser} hideActions={hideActions} data-sentry-element="Header" data-sentry-source-file="messages.tsx" />
        {isEditing ? <form className="flex size-full flex-col items-end gap-4 px-6 py-4" onSubmit={() => {
        setIsEditing(false);
        regenerate(message.id, {
          content: newValue
        });
      }}>
            <Textarea ref={textAreaRef} id={`message-editor-${message.id}`} className="size-full resize-none rounded-lg border-none bg-transparent p-0 shadow-none" autoFocus spellCheck={false} autoComplete="off" autoCorrect="off" name="message" rows={1} value={newValue} onChange={e => {
          setNewValue(e.target.value);
        }} onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setIsEditing(false);
            regenerate(message.id, {
              content: newValue
            });
          }
        }} autoresize maxRows={10} />
            <div className="flex gap-2">
              <ChatModelPicker />
              <Button variant={'ghost'} type="button" onClick={() => setIsEditing(false)} className="shrink-0 rounded-lg shadow-none">
                Cancel
                <X size={20} />
              </Button>
              <Button variant={'default'} className="shrink-0 rounded-lg shadow-none">
                Send
                <Send size={20} />
              </Button>
            </div>
          </form> : <motion.div className={cn('max-w-full overflow-x-auto overflow-y-hidden', !isInContext && 'gradient-mask-b-50')} style={{
        height: isInContext ? 'auto' : '2rem'
      }} animate={{
        height: isInContext ? 'auto' : '2rem'
      }} transition={{
        duration: 0.5
      }}>
            <Markdown className={cn('prose max-w-full break-words px-4 py-2 text-foreground transition-all duration-300 dark:prose-invert prose-p:leading-relaxed prose-pre:bg-transparent prose-pre:p-0 prose-pre:text-muted-foreground sm:px-6 sm:py-4', className)}>
              {message.content?.toString() ?? ''}
            </Markdown>
          </motion.div>}
      </div>
    </MessageContext.Provider>;
}
export function BotCard({
  children,
  showAvatar = true
}: {
  children: React.ReactNode;
  showAvatar?: boolean;
}) {
  return <div className="group relative flex items-start md:-ml-12" data-sentry-component="BotCard" data-sentry-source-file="messages.tsx">
      <div className={cn('flex size-[24px] shrink-0 select-none items-center justify-center rounded-md border bg-primary text-primary-foreground shadow-sm', !showAvatar && 'invisible')}>
        <IconOpenAI data-sentry-element="IconOpenAI" data-sentry-source-file="messages.tsx" />
      </div>
      <div className="ml-4 flex-1 pl-2">{children}</div>
    </div>;
}
export function SystemMessage({
  children
}: {
  children: React.ReactNode;
}) {
  return <div className={'mt-2 flex items-center justify-center gap-2 text-xs text-gray-500'} data-sentry-component="SystemMessage" data-sentry-source-file="messages.tsx">
      <div className={'max-w-[600px] flex-initial p-2'}>{children}</div>
    </div>;
}
export function LoadingMessage() {
  return <p className="text-sm font-medium text-muted-foreground" data-sentry-component="LoadingMessage" data-sentry-source-file="messages.tsx">
      Working on it...
    </p>;
}