import { useChat } from '@/lib/hooks/use-chat';
import { cn } from '@/lib/utils';
import { ArrowDown, CircleStop, Send } from 'lucide-react';
import { useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { ChatModelPicker } from './chat-model-picker';
const placeholders = ['Whisper your wildest ideas to the AI genie...', 'Plant your thought seeds here and watch them grow!', "Unravel your mind's spaghetti—I'll grab the fork!", 'Toss your mental confetti into this digital parade!', 'Teleport your thoughts through the keyboar-dimensional portal', 'Unleash your inner Shakespeare (or Yoda) here', "Feed me your brain bytes, I'm data-licious!", 'Spill the alphabetti spaghetti of your mind', 'Launch your word rockets into my neural stratosphere', 'Weave your verbal tapestry in this digital loom', 'Summon your inner sage—or silly goose—right here', 'Whisper sweet nothings (or complex somethings) to me', 'Embark on a keystroke adventure in this text galaxy', 'Compose your mental symphony in this AI concert hall', 'Doodle with words in this linguistic sketchpad'];
export function MessageInput() {
  const {
    isLoading,
    handleSubmit,
    stop,
    input,
    handleInputChange,
    error,
    scroll,
    messages
  } = useChat();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current && !isLoading) {
      inputRef.current.focus();
    }
  }, [isLoading]);
  const modelPickerRef = useRef<HTMLButtonElement>(null);
  useHotkeys('mod+m', (e, handler) => {
    e.preventDefault();
    modelPickerRef.current?.click();
  }, {
    preventDefault: true,
    enableOnFormTags: ['textarea']
  });
  // Listen to the closing of the model picker adn focus the text area.
  // This can be by looking at the data-state="open"|"closed" attribute
  useEffect(() => {
    if (modelPickerRef.current) {
      const observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
          if (mutation.type === 'attributes') {
            if (mutation.attributeName === 'data-state') {
              if ((mutation.target as Element).getAttribute('data-state') === 'closed') {
                inputRef.current?.focus();
              }
            }
          }
        }
      });
      observer.observe(modelPickerRef.current, {
        attributes: true,
        attributeFilter: ['data-state']
      });
      return () => {
        observer.disconnect();
      };
    }
  }, []);
  const randomPlaceholder = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * placeholders.length);
    return placeholders[randomIndex];
  }, []);
  return <form className="relative z-10 flex w-full max-w-2xl items-end gap-2 rounded-2xl bg-muted p-2 md:max-w-3xl" onSubmit={handleSubmit} data-sentry-component="MessageInput" data-sentry-source-file="message-input.tsx">
      <div className={cn('absolute inset-x-0 flex justify-center transition-all', !scroll.isEndVisible && messages.length > 0 ? '-top-0 z-10 translate-y-[-150%] delay-500' : '-top-0 -translate-y-full opacity-0')}>
        <button type="button" className="group rounded-full bg-muted-foreground p-1.5 shadow-lg duration-500" onClick={() => {
        scroll.scrollToBottom('smooth');
        inputRef.current?.focus();
      }}>
          <ArrowDown className="size-5 text-background transition-transform duration-300 group-hover:translate-y-0.5" data-sentry-element="ArrowDown" data-sentry-source-file="message-input.tsx" />
        </button>
      </div>
      <Textarea ref={inputRef} tabIndex={0} placeholder={randomPlaceholder} className="peer grow resize-none rounded-lg bg-input px-3 py-2" autoFocus spellCheck={false} autoComplete="off" autoCorrect="off" name="message" rows={1} value={input} onChange={handleInputChange} onKeyDown={e => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    }} autoresize maxRows={10} disabled={isLoading || !!error} data-sentry-element="Textarea" data-sentry-source-file="message-input.tsx" />
      <div className="group flex items-center gap-2 peer-focus:[&>.model-picker]:my-1 peer-focus:[&>.model-picker]:gap-0 peer-focus:[&>.model-picker]:rounded-full peer-focus:[&>.model-picker]:px-2 peer-focus:[&>.model-picker]:py-1 peer-focus:[&>.model-picker]:[&_svg]:w-0">
        <ChatModelPicker ref={modelPickerRef} className="model-picker" data-sentry-element="ChatModelPicker" data-sentry-source-file="message-input.tsx" />
        <Button variant={'ghost'} type={isLoading ? 'button' : 'submit'} className="shrink-0 rounded-lg !p-2 shadow-none" onClick={isLoading ? stop : () => {}} disabled={!isLoading && !input.trim()} data-sentry-element="Button" data-sentry-source-file="message-input.tsx">
          {isLoading ? <CircleStop size={24} /> : <Send size={24} />}
        </Button>
      </div>
    </form>;
}