'use client';

import { ChatAPIBody } from '@/app/api/chat/route';
import { AddCreditsDialog } from '@/components/payments/add-credits-dialog';
import { SupabaseChat, SupabaseMessage, SupabaseModel } from '@/lib/supabase/actions';
import { captureException } from '@sentry/nextjs';
import { ChatRequestOptions } from 'ai';
import { useChat as _useChat } from 'ai/react';
import { usePostHog } from 'posthog-js/react';
import { createContext, Dispatch, PropsWithChildren, RefObject, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';
import { useImmer } from 'use-immer';
import { createBrowserSupabase } from '../supabase/browser';
import { Tables } from '../supabase/database.types';
import { convertSupabaseToAIMessage } from '../supabase/utils';
import { User } from '../types';
import { uuid } from '../utils';
import { useChatRedirect, useChatScroll, useDataHandler, useMergeMessages, useSyncLLMContextMessages } from './use-chat-helpers';
import { useInstantState } from './use-instant-state';
export type ChatContextProviderProps = {
  /**
   * The chat ID, if chat is present, it should be the chat ID
   */
  id: string;
  /**
   * All the models users can choose from
   */
  availableModels: SupabaseModel[];
  /**
   * The chat fetched from the server
   */
  chat?: SupabaseChat;
  /**
   * The chat participant fetched from the server
   */
  chatParticipant?: SupabaseChat['chat_participants'][number];
  /**
   * The current user
   */
  user: User;
};
type ChatContextType = Omit<NonNullable<ReturnType<typeof _useChat>>, 'messages' | 'setMessages'> & {
  user: User;
  chatParticipant?: SupabaseChat['chat_participants'][number];
  availableModels: SupabaseModel[];
  /**
   * The current model
   */
  model: SupabaseModel | undefined;
  /**
   * The current model ref (updated immediately)
   */
  instantModel: RefObject<SupabaseModel | undefined>;
  /**
   * Set the current model
   */
  setModel: Dispatch<SetStateAction<SupabaseModel | undefined>>;
  /**
   * The messages in the format of the supabase messages
   */
  messages: SupabaseMessage[];
  /**
   * The scrollRef and visibilityRef for the chat
   */
  scroll: Pick<ReturnType<typeof useChatScroll>, 'scrollRef' | 'visibilityRef' | 'isEndVisible' | 'scrollToBottom'>;
  /**
   * Helper function to check if a message is in the llm context
   */
  isInContext: (id: string) => boolean;
  /**
   * Helper function to toggle the is_in_context field of a message
   */
  toggleInContext: (id: string, isInContext: boolean | null) => void;
  /**
   * Helper function to remove a message
   */
  removeMessage: (id: string) => void;
  /**
   * Helper function to regenerate a message, deletes all message history after the regenerated message
   * @param id The ID of the message to regenerate
   * @param options.model The model to use for the regeneration
   * @param options.content The content of the message to regenerate, only taken into consideration if the message is a user message
   */
  regenerate: (id: string, options?: {
    model?: SupabaseModel;
    content?: string;
  }) => void;
};
const ChatContext = createContext<ChatContextType | null>(null);
export function ChatContextProvider({
  children,
  id: chatId,
  availableModels,
  chat,
  chatParticipant,
  user
}: PropsWithChildren<ChatContextProviderProps>) {
  if (chat?.id && chatId !== chat.id) {
    throw new Error('Chat ID mismatch');
  }
  const posthog = usePostHog();
  const [model, setModel, instantModel] = useInstantState<SupabaseModel | undefined>(chat?.messages.toReversed().find(m => m.llm_usage?.llm)?.llm_usage?.llm || (user.default_llm?.is_active ? user.default_llm : undefined) || availableModels.find(m => m.name === 'GPT 4o Mini') || availableModels[0]);
  useEffect(() => {
    if (!chat?.messages.length) {
      setModel((user.default_llm?.is_active ? user.default_llm : undefined) || availableModels.find(m => m.name === 'GPT 4o Mini') || availableModels[0]);
    }
  }, [user.default_llm?.is_active, chat?.messages.length, setModel, user.default_llm, availableModels]);
  const [openAddCreditsDialog, setOpenAddCreditsDialog] = useState(false);
  const billingAccount = useMemo(() => user.user_billing_accounts.find(b => b.is_active)?.billing_account, [user.user_billing_accounts]);
  const [supabaseMessages, setSupabaseMessages] = useImmer(chat?.messages || []);
  const responseId = useRef<string>(uuid());
  const {
    messages: llmContextMessages,
    setMessages: setLLMContextMessages,
    handleSubmit: unused_handleSubmit,
    ...aux
  } = _useChat({
    initialMessages: chat?.messages.map(convertSupabaseToAIMessage),
    id: chatId,
    generateId: () => {
      // Intercepting the generation of uuids, so we know in advance the ID
      // of the messages that are generated by the hook. When
      const id = responseId.current;
      responseId.current = uuid();
      return id;
    },
    keepLastMessageOnError: true,
    sendExtraMessageFields: true,
    body: {
      chatId,
      model: instantModel.current?.name || '',
      responseId: responseId.current
    } satisfies Omit<ChatAPIBody, 'messages'>,
    onError: error => {
      captureException(error);
    }
  });
  useSyncLLMContextMessages({
    paused: aux.isLoading || !!aux.error,
    setLLMContextMessages,
    supabaseMessages,
    chatParticipant
  });

  // @ts-ignore `Type instantiation is excessively deep and possibly infinite.` for some reason 🤷‍♂️
  useDataHandler({
    data: aux.data,
    setSupabaseMessages,
    llmContextMessages
  });
  const messages = useMergeMessages({
    id: chatId,
    model,
    user,
    supabaseMessages,
    llmContextMessages
  });
  const appendWithoutGeneration = useCallback((content: string) => {
    const newMessage: Tables<'messages'> = {
      id: uuid(),
      created_at: new Date().toISOString(),
      role: 'user',
      content,
      chat_id: chatId,
      is_removed: false,
      llm_usage_id: null,
      owner_id: user.id
    };
    setSupabaseMessages(draft => {
      draft.push({
        ...newMessage,
        is_in_context: null,
        llm_usage: null,
        user: user
      });
    });
    createBrowserSupabase().from('messages').insert(newMessage).select().single().then(r => {
      if (r.error) {
        captureException(r.error);
        toast.error('Failed to save message to the database');
        console.error(r.error);
        return;
      }
      setSupabaseMessages(draft => {
        const index = draft.findIndex(m => m.id === newMessage.id);
        if (index !== -1) {
          draft[index] = {
            ...draft[index],
            ...r.data
          };
        }
      });
    });
    aux.setInput('');
  }, [aux, chatId, setSupabaseMessages, user]);
  const handleSubmit = useCallback(async (event?: {
    preventDefault?: () => void;
  }, chatRequestOptions?: ChatRequestOptions) => {
    event?.preventDefault?.();
    if (aux.input.trim().length === 0) return;
    if (!instantModel.current?.name) {
      appendWithoutGeneration(aux.input);
      return;
    }
    if ((instantModel.current?.pricing?.per_input_token ?? 0) + (instantModel.current?.pricing?.per_output_token ?? 0) + (instantModel.current?.pricing?.per_message ?? 0) > 0 && (billingAccount?.balance ?? 0) < 0) {
      posthog.capture('message_blocked', {
        model: instantModel.current?.name,
        reason: 'insufficient_credits',
        type: 'new'
      });
      setOpenAddCreditsDialog(true);
      return;
    }
    posthog.capture('message_sent', {
      model: instantModel.current?.name,
      type: 'new'
    });
    aux.append({
      role: 'user',
      content: aux.input,
      id: uuid()
    }, chatRequestOptions);
    aux.setInput('');
  }, [appendWithoutGeneration, aux, billingAccount?.balance, instantModel, posthog]);
  const isInContext = useCallback((id: string) => {
    return llmContextMessages.findIndex(m => m.id === id) !== -1;
  }, [llmContextMessages]);
  const toggleInContext = useCallback((id: string, isInContext: boolean | null) => {
    setSupabaseMessages(draft => {
      const index = draft.findIndex(m => m.id === id);
      if (index !== -1) {
        draft[index].is_in_context = isInContext;
      }
    });
  }, [setSupabaseMessages]);
  const removeMessage = useCallback(async (id: string) => {
    const message = messages.find(m => m.id === id);
    if (!message) {
      toast.error('Message not found');
      return;
    }
    posthog.capture('message_deleted');
    setSupabaseMessages(draft => {
      const index = draft.findIndex(m => m.id === id);
      if (index !== -1) {
        draft.splice(index, 1);
      }
    });
    createBrowserSupabase().from('messages').update({
      content: null,
      is_removed: true
    }).eq('id', id).then(({
      error
    }) => {
      if (error) {
        toast.error('There was an error deleting the messages');
        captureException(error);
        setSupabaseMessages(draft => {
          const index = draft.findIndex(m => m.id === id);
          if (index !== -1) {
            draft.splice(index, 1);
          }
        });
        return;
      }
    });
  }, [messages, posthog, setSupabaseMessages]);
  const regenerate: ChatContextType['regenerate'] = useCallback((id, {
    model,
    content
  } = {}) => {
    const messageIndex = messages.findIndex(m => m.id === id);
    const message = messageIndex !== -1 ? messages[messageIndex] : null;
    const isLLM = message?.llm_usage_id;
    const deleteFromTimestamp = isLLM ? messages[messageIndex - 1]?.created_at : message?.created_at;
    if (!message) {
      captureException(new Error('Message to regenerate is not found or not from the LLM'));
      return;
    }
    if (!deleteFromTimestamp) {
      captureException(new Error("Couldn't determine the timestamp to delete from"));
      return;
    }
    content ??= messages[messageIndex - 1]?.content || undefined;
    if (content === undefined) {
      captureException(new Error('Content to regenerate is not provided or is empty'));
      return;
    }
    if (model) {
      setModel(model);
    }
    if ((instantModel.current?.pricing?.per_input_token ?? 0) + (instantModel.current?.pricing?.per_output_token ?? 0) + (instantModel.current?.pricing?.per_message ?? 0) > 0 && (billingAccount?.balance ?? 0) < 0) {
      posthog.capture('message_blocked', {
        model: instantModel.current?.name,
        reason: 'insufficient_credits',
        type: 'regenerate'
      });
      setOpenAddCreditsDialog(true);
      return;
    }
    createBrowserSupabase().from('messages').update({
      content: null,
      is_removed: true
    }).eq('chat_id', chatId).gte('created_at', deleteFromTimestamp).then(({
      error
    }) => {
      if (error) {
        toast.error('There was an error deleting the messages');
        captureException(error);
        return;
      }
    });
    setSupabaseMessages(draft => {
      const index = draft.findIndex(m => m.id === id);
      if (index !== -1) {
        return draft.slice(0, index - (isLLM ? 1 : 0));
      }
      return draft;
    });
    setLLMContextMessages(prev => {
      const index = prev.findIndex(m => m.id === id);
      if (index !== -1) {
        return prev.slice(0, index - (isLLM ? 1 : 0));
      }
      return prev;
    });
    if (!instantModel.current?.name) {
      appendWithoutGeneration(content);
      return;
    }
    posthog.capture('message_sent', {
      model: instantModel.current?.name,
      type: 'regenerate'
    });
    aux.append({
      content,
      id: uuid(),
      role: 'user'
    }, {
      body: {
        model: instantModel.current?.name || ''
      }
    });
  }, [appendWithoutGeneration, aux, billingAccount?.balance, chatId, instantModel, messages, posthog, setLLMContextMessages, setModel, setSupabaseMessages]);
  useChatRedirect({
    messages,
    chatId
  });
  const scroll = useChatScroll({
    messages
  });
  return <ChatContext.Provider value={{
    user,
    chatParticipant,
    availableModels,
    model,
    instantModel,
    setModel,
    messages,
    scroll,
    isInContext,
    toggleInContext,
    removeMessage,
    regenerate,
    handleSubmit,
    ...aux
  }} data-sentry-element="unknown" data-sentry-component="ChatContextProvider" data-sentry-source-file="use-chat.tsx">
      <AddCreditsDialog billingAccount={billingAccount!} open={openAddCreditsDialog} setOpen={setOpenAddCreditsDialog} data-sentry-element="AddCreditsDialog" data-sentry-source-file="use-chat.tsx" />
      {children}
    </ChatContext.Provider>;
}
export function useChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatContextProvider');
  }
  return context;
}