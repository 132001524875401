import { CodeBlock } from '@/components/ui/codeblock';
import { cn } from '@/lib/utils';
import 'katex/dist/katex.min.css';
import { FC, memo } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
const convertLatexDelimiters = (content: string | null | undefined) => {
  if (!content) return content;
  return content.replace(/\\\(/g, '$').replace(/\\\)/g, '$').replace(/\\\[/g, '$$').replace(/\\\]/g, '$$');
};
export const Markdown: FC<Options> = memo(({
  children,
  ...props
}) => <ReactMarkdown remarkPlugins={[remarkGfm, remarkMath]} rehypePlugins={[rehypeKatex]} components={{
  p({
    children
  }) {
    return <p className="mb-2 last:mb-0">{children}</p>;
  },
  code({
    className,
    children,
    ...props
  }) {
    const childrenString = children?.toString();
    if (childrenString?.length) {
      if (childrenString[0] == '▍') {
        return <span className="mt-1 animate-pulse cursor-default">▍</span>;
      }
    }
    const match = /language-(\w+)/.exec(className || '');
    const isBlock = childrenString?.includes('\n');
    return match || isBlock ? <CodeBlock key={Math.random()} language={match?.[1] || ''} value={String(children).replace(/\n$/, '')} {...props} /> : <code className={cn('not-prose m-0.5 rounded-lg border bg-muted px-1.5 py-0.5 font-mono text-sm font-medium text-orange-500 shadow-md', className)}>
              {children}
            </code>;
  }
}} {...props}>
      {convertLatexDelimiters(children)}
    </ReactMarkdown>, (prevProps, nextProps) => prevProps.children === nextProps.children && prevProps.className === nextProps.className);
Markdown.displayName = 'Markdown';