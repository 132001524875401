import { useChat } from '@/lib/hooks/use-chat';
import { forwardRef } from 'react';
import { ModelPicker } from './model-picker';
export const ChatModelPicker = forwardRef<HTMLButtonElement, {
  className?: string;
}>(({
  className
}, ref) => {
  const {
    availableModels,
    model,
    setModel
  } = useChat();
  return <ModelPicker ref={ref} model={model} setModel={setModel} availableModels={availableModels} className={className} />;
});
ChatModelPicker.displayName = 'ChatModelPicker';