import { Message } from 'ai'
import { SupabaseMessage } from './actions'
export { v4 as uuid } from 'uuid'

export function convertSupabaseToAIMessage(message: SupabaseMessage): Message {
  return {
    role: message.role,
    id: message.id,
    content: message.content as string,
    createdAt: new Date(message.created_at),
  }
}

export function convertAIToSupabase(
  message: Message,
  data: Omit<SupabaseMessage, 'id' | 'content' | 'created_at' | 'role'>,
): SupabaseMessage {
  return {
    role: message.role,
    id: message.id,
    content: message.content as string,
    created_at: message.createdAt?.toISOString()!,
    ...data,
  }
}

let _url = ''

switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
  case 'production':
    _url = `https://${process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL}`
    break
  case 'preview':
    _url = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    break
  default:
    _url = 'http://localhost:3000'
}

export const url = _url
