'use client';

import { ChatContextProvider, ChatContextProviderProps, useChat } from '@/lib/hooks/use-chat';
import { ChatStart } from './chat-start';
import { ChatError } from './error';
import { MessageInput } from './message-input';
import { Message as DisplayMessage, LoadingMessage } from './messages';
export function ChatBody() {
  const {
    user,
    chatParticipant,
    messages,
    isInContext,
    toggleInContext,
    isLoading,
    scroll
  } = useChat();
  return <div className="mb-2 flex min-w-0 shrink grow flex-col items-center justify-between sm:mb-0" data-sentry-component="ChatBody" data-sentry-source-file="chat.tsx">
      <div ref={scroll.scrollRef} className="-mr-2 shrink grow self-stretch overflow-y-auto pr-2 duration-500">
        <div className="mx-auto flex w-full max-w-3xl flex-col items-stretch gap-8 sm:px-8">
          <ChatStart key={'chat-start'} data-sentry-element="ChatStart" data-sentry-source-file="chat.tsx" />
          {messages.map((message, index) => <DisplayMessage key={index} currentUser={user} message={message} hideActions={isLoading && index === messages.length - 1} defaultContextLength={chatParticipant?.default_context_length || 12} isContextAuto={message.is_in_context === null} isInContext={isInContext(message.id)} toggleInContext={v => toggleInContext(message.id, v)} />
        //  {message.toolInvocations?.map(toolInvocation => {
        //   const toolCallId = toolInvocation.toolCallId
        //   const addResult = (result: string) =>
        //     addToolResult({ toolCallId, result })

        //   // render confirmation tool (client-side tool with user interaction)
        //   if (toolInvocation.toolName === 'askForConfirmation') {
        //     return (
        //       <div key={toolCallId}>
        //         {toolInvocation.args.message}
        //         <div>
        //           {'result' in toolInvocation ? (
        //             <b>{toolInvocation.result}</b>
        //           ) : (
        //             <>
        //               <button onClick={() => addResult('Yes')}>
        //                 Yes
        //               </button>
        //               <button onClick={() => addResult('No')}>No</button>
        //             </>
        //           )}
        //         </div>
        //       </div>
        //     )
        //   }

        //   // other tools:
        //   return 'result' in toolInvocation ? (
        //     <div key={toolCallId}>
        //       Tool call {`${toolInvocation.toolName}: `}
        //       {toolInvocation.result}
        //     </div>
        //   ) : (
        //     <div key={toolCallId}>
        //       Calling {toolInvocation.toolName}...
        //     </div>
        //   )
        // })}
        )}
          {isLoading && <LoadingMessage key={'loading-message'} />}
          <ChatError key={'chat-error'} data-sentry-element="ChatError" data-sentry-source-file="chat.tsx" />
          <div key={'chat-scroll-ref'} ref={scroll.visibilityRef} className="-mt-8 min-h-28 border-blue-500" />
        </div>
      </div>

      <MessageInput data-sentry-element="MessageInput" data-sentry-source-file="chat.tsx" />
    </div>;
}
export function Chat(props: Omit<ChatContextProviderProps, 'children'>) {
  return <ChatContextProvider {...props} data-sentry-element="ChatContextProvider" data-sentry-component="Chat" data-sentry-source-file="chat.tsx">
      <ChatBody data-sentry-element="ChatBody" data-sentry-source-file="chat.tsx" />
    </ChatContextProvider>;
}