import { SupabaseChat, SupabaseMessage, SupabaseModel } from '@/lib/supabase/actions';
import { Message } from 'ai';
import { usePathname, useRouter } from 'next/navigation';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { useUser } from '../supabase/browser';
import { convertAIToSupabase, convertSupabaseToAIMessage } from '../supabase/utils';
import { User } from '../types';
import { isObject, sortMessageFunc } from '../utils';
import { useScrollAnchor } from './use-scroll-anchor';
import { useSidebar } from './use-sidebar';
export function useSyncLLMContextMessages({
  paused,
  setLLMContextMessages,
  supabaseMessages,
  chatParticipant
}: {
  paused: boolean;
  setLLMContextMessages: Dispatch<SetStateAction<Message[]>>;
  supabaseMessages: SupabaseMessage[];
  chatParticipant?: SupabaseChat['chat_participants'][number];
}) {
  // Keep the llm context messages in sync with the supabase messages
  useEffect(() => {
    if (paused) return;
    setLLMContextMessages(supabaseMessages.filter((m, i, a) => m.is_in_context !== null ? m.is_in_context : true
    // A future feature, automatic context shortening 👇
    // : a.length <= i + (chatParticipant?.default_context_length || 10),
    ).map(m => convertSupabaseToAIMessage(m)));
  }, [supabaseMessages, chatParticipant?.default_context_length, setLLMContextMessages, paused]);
}

/**
 * Handles the incoming data from the chat and updates the supabase messages
 */
export function useDataHandler({
  data,
  setSupabaseMessages,
  llmContextMessages
}: {
  data?: any[] | undefined;
  setSupabaseMessages: Dispatch<SetStateAction<SupabaseMessage[]>>;
  llmContextMessages: Message[];
}) {
  const {
    refetch
  } = useUser();
  // Update the messages when saved in the database
  useEffect(() => {
    if (!data || !data.length) return;
    setSupabaseMessages(messages => {
      let edited = false;
      for (const d of data) {
        if (!isObject(d)) continue;
        if (!('message' in d && 'responseId' in d)) continue;
        if (llmContextMessages.findIndex(m => m.id === d.responseId) === -1) continue;
        edited = true;
        const supabaseMessage = d.message as SupabaseMessage;
        // Remove the message with the same id from messages
        const savedMessageIndex = messages.findIndex(m => m.id === supabaseMessage.id);
        if (savedMessageIndex !== -1) {
          messages.splice(savedMessageIndex, 1);
        }
        messages.push(supabaseMessage);
        messages.sort(sortMessageFunc);
        refetch();
      }
      return messages;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length, setSupabaseMessages, refetch]);
}

/**
 * Merges the supabase messages with the llm context messages
 */
export function useMergeMessages({
  id,
  model,
  user,
  supabaseMessages,
  llmContextMessages
}: {
  id: string;
  model: SupabaseModel | undefined;
  user: User;
  supabaseMessages: SupabaseMessage[];
  llmContextMessages: Message[];
}) {
  return useMemo(() => [...supabaseMessages, ...llmContextMessages.map(m => convertAIToSupabase(m, {
    chat_id: id,
    is_removed: false,
    llm_usage: m.role === 'assistant' && model ? {
      llm: model,
      additional_data: null,
      id: 'NOT_LOADED',
      input_tokens: 0,
      output_tokens: 0,
      llm_id: model.id,
      pricing_id: model.pricing_id,
      pricing: model.pricing,
      created_at: new Date().toISOString()
    } : null,
    owner_id: user.id,
    user: user,
    llm_usage_id: 'NOT_LOADED',
    is_in_context: null
  }))].filter((m, i, a) => a.findIndex(m2 => m2.id === m.id) === i), [supabaseMessages, llmContextMessages, id, model, user]);
}

/**
 * Changes the url to the chat id when new chat has started
 * and refreshes the sidebar list of chats
 */
export function useChatRedirect({
  messages,
  chatId
}: {
  messages: SupabaseMessage[];
  chatId: string;
}) {
  const path = usePathname();
  const {
    refreshChats
  } = useSidebar();
  const router = useRouter();
  const redirectedRef = useRef(false);
  useEffect(() => {
    switch (messages.length) {
      case 1:
        window.history.replaceState({}, '', `/chat/${chatId}`);
        break;
      case 2:
        if (redirectedRef.current) return;
        redirectedRef.current = true;
        refreshChats();
        router.refresh();
        break;
      default:
        break;
    }
  }, [path, messages.length, chatId, refreshChats, router]);
}

/**
 * Scroll to bottom when new message is appended and on init
 */
export function useChatScroll({
  messages
}: {
  messages: SupabaseMessage[];
}) {
  const {
    scrollRef,
    visibilityRef,
    scrollToBottom,
    isEndVisible
  } = useScrollAnchor();
  useEffect(() => {
    if (messages.length === 0) {
      scrollToBottom('instant');
    } else {
      scrollToBottom('smooth');
    }
  }, [messages.length, scrollToBottom]);
  return {
    scrollRef,
    visibilityRef,
    isEndVisible,
    scrollToBottom
  };
}