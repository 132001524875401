import * as React from 'react';
import { cn } from '@/lib/utils';
import AutoresizeTextarea, { TextareaAutosizeProps } from 'react-textarea-autosize';
export type TextareaProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'style'> & ((TextareaAutosizeProps & {
  autoresize: boolean;
}) | {
  autoresize?: never;
});
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({
  className,
  autoresize,
  ...props
}, ref) => {
  const Comp = autoresize ? AutoresizeTextarea : 'textarea';
  return <Comp className={cn('w-full rounded-lg border-none bg-muted px-4 py-3.5 shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-0 disabled:cursor-not-allowed disabled:opacity-50', className)} ref={ref} {...props} />;
});
Textarea.displayName = 'Textarea';
export { Textarea };