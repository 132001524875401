import { useCallback, useEffect, useRef, useState } from 'react';
export const useScrollAnchor = () => {
  // const messagesRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null);
  const visibilityRef = useRef<HTMLDivElement>(null);
  const [isScrolledAtBottom, setIsScrolledAtBottom] = useState(true);
  const [isEndVisible, setIsEndVisible] = useState(false);
  const scrollToBottom = useCallback((behavior: 'smooth' | 'instant' = 'smooth') => {
    visibilityRef.current?.scrollIntoView({
      block: 'end',
      behavior
    });
  }, []);
  useEffect(() => {
    if (visibilityRef.current) {
      visibilityRef.current.scrollIntoView({
        block: 'end',
        behavior: 'instant'
      });
    }
  }, []);
  useEffect(() => {
    if (scrollRef.current) {
      if (isScrolledAtBottom && !isEndVisible) {
        visibilityRef.current?.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
          inline: 'nearest'
        });
      }
    }
  }, [isScrolledAtBottom, isEndVisible]);
  useEffect(() => {
    // Listen to the scroll of the chat
    if (scrollRef.current) {
      const handleScroll = (event: Event) => {
        const target = event.target as HTMLDivElement;
        const offset = 5;
        const isAtBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - offset;
        setIsScrolledAtBottom(isAtBottom);
      };
      scrollRef.current.addEventListener('scroll', handleScroll, {
        passive: true
      });
      const scrollRefCopy = scrollRef.current;
      return () => {
        scrollRefCopy?.removeEventListener('scroll', handleScroll);
      };
    }
  });
  useEffect(() => {
    // Listen to the visibility change of the end of the chat
    if (visibilityRef.current) {
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsEndVisible(true);
          } else {
            setIsEndVisible(false);
          }
        });
      });
      observer.observe(visibilityRef.current);
      return () => {
        observer.disconnect();
      };
    }
  });
  return {
    // messagesRef,
    scrollRef,
    visibilityRef,
    scrollToBottom,
    isScrolledAtBottom,
    isEndVisible
  };
};