import { useChat } from '@/lib/hooks/use-chat';
import { cn } from '@/lib/utils';
import { Rocket } from 'lucide-react';
export function ChatStart() {
  const {
    messages
  } = useChat();
  return <div className="flex h-1/2 shrink-0 flex-col items-center justify-center gap-4 py-12" data-sentry-component="ChatStart" data-sentry-source-file="chat-start.tsx">
      <Rocket size={80} className={cn(messages.length === 0 && 'duration-500 animate-in fade-in slide-in-from-bottom-12 slide-in-from-left-12')} data-sentry-element="Rocket" data-sentry-source-file="chat-start.tsx" />
      {messages.length === 0 ? <p className={cn('text-center duration-1000 animate-in fade-in-0 slide-in-from-bottom-4')}>
          Start a new conversation
        </p> : <p className={cn('text-center text-muted-foreground')}>
          This is the start of this chat
        </p>}
    </div>;
}