import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from '@/lib/utils';
import { Slot } from '@radix-ui/react-slot';
import { buttonVariants } from './button';
const alertVariants = cva('relative w-full rounded-lg border px-4 py-3 text-sm [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg]:text-foreground [&>svg~*:not(button)]:pl-7', {
  variants: {
    variant: {
      default: 'bg-background text-foreground',
      destructive: 'border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive',
      warning: 'border-amber-600/50 text-amber-600 dark:border-amber-600 [&>svg]:text-amber-600'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
const Alert = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>>(({
  className,
  variant,
  ...props
}, ref) => <div ref={ref} role="alert" className={cn(alertVariants({
  variant
}), className)} {...props} />);
Alert.displayName = 'Alert';
const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({
  className,
  ...props
}, ref) => <h5 ref={ref} className={cn('mb-1 font-semibold leading-none tracking-tight', className)} {...props} />);
AlertTitle.displayName = 'AlertTitle';
const AlertDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(({
  className,
  ...props
}, ref) => <div ref={ref} className={cn('text-sm [&_p]:leading-relaxed', className)} {...props} />);
AlertDescription.displayName = 'AlertDescription';
const AlertAction = React.forwardRef<HTMLButtonElement, React.ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
}>(({
  className,
  asChild,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(asChild || buttonVariants({
    variant: 'outline',
    size: 'sm'
  }), 'absolute right-3 top-3', className)} ref={ref} {...props} />;
});
AlertAction.displayName = 'AlertAction';
export { Alert, AlertAction, AlertDescription, AlertTitle };