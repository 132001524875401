import { useCallback, useEffect, useRef, useState } from 'react';
export function useInstantState<T>(initialValue: T) {
  const ref = useRef<T>(initialValue);
  const [state, _setState] = useState(initialValue);
  const setState = useCallback((value: T | ((prevState: T) => T)) => {
    if (typeof value === 'function') {
      value = (value as (prevState: T) => T)(ref.current);
    }
    _setState(value);
    ref.current = value;
  }, []);
  useEffect(() => {
    ref.current = state;
  }, [state]);
  return [state, setState, ref] as const;
}